<h2>{{ company?.name }}</h2>
<p>Id: {{ company?.id }}</p>
<p>reg com: {{ company?.regComName }}</p>
<p>cui: {{ company?.cui }}</p>
<p>owner: {{ company?.ownerId }}</p>
<p>Efacutra: {{ company?.efacturaAuthorized }}</p>

<button
  (click)="syncBills()"
  pButton
  size="small"
  severity="info"
  *ngIf="eFacturaAuthorized == true"
>
  syncBills
</button>

<h4>User Management</h4>

<ul *ngFor="let user of users">
  <li>
    <div>
      <p>{{ user.firstName }} {{ user.lastName }}</p>
      <button
        size="small"
        severity="success"
        [routerLink]="['/user', user.userId]"
        pButton
      >
        Go to User
      </button>
      <p>{{ user.email }}</p>
      <p>{{ user.userId }}</p>
    </div>
    <button size="small" severity="help" pButton>Update User</button>

    <button size="small" severity="danger" pButton>Remove User</button>
  </li>
</ul>

<button size="small" (click)="addUser()" pButton>Add user</button>

<app-facturi [companyId]="company?.id"></app-facturi>

<h3>Subscriptions:</h3>

<div  style="display: flex;">
  <div  *ngFor="let sub of subscriptions" class="subscription-container flex">
    <div class="sub-top-container flex">
      <span
        class="active-status"
        [ngClass]="
          isSubscriptionActive(sub) ? 'active-status' : 'inactive-status'
        "
        >{{ getActiveSubscriptionLabel(sub) }}</span
      >
    </div>
    <div class="separator-container">
      <div class="separator" style="width: 10%">
        <div class="sepatator-top"></div>
      </div>
      <div class="image-container" style="width: 20%">
        <!-- <img height="50px" src="assets/images/subscription/crown.jpg" /> -->
      </div>
      <div class="separator" style="width: 70%">
        <div class="sepatator-top"></div>
      </div>
    </div>

    <div class="sub-bottom-container flex">
      <h3>{{ sub.name }}</h3>
      <div class="details-container">
        <p class="details-title">Detalii abonament:</p>
        <p *ngIf="isSubscriptionActive">Expira in {{ getEndDate(sub) }} zile</p>
        <p>
          <p-meterGroup
            labelPosition="start"
            [value]="
              getMeter(
                sub.limits?.wayBillLimit,
                sub.subscriptionType.limits.wayBillLimit,
                ' Limita de facturi'
              )
            "
          />
        </p>
        <p>
          <p-meterGroup
            labelPosition="start"
            [value]="
              getMeter(
                sub.limits?.wayBillLimit,
                sub.subscriptionType.limits.wayBillLimit,
                ' Limita de avize'
              )
            "
          />
        </p>
        <p>
          <p-meterGroup
            labelPosition="start"
            [value]="
              getMeter(
                sub.limits?.wayBillLimit,
                sub.subscriptionType.limits.wayBillLimit,
                ' Limita de utilizatori'
              )
            "
          />
        </p>
      </div>

      <div class="button-container flex">
        <button size="small" (click)="navigateToSubscription(sub)" pButton>
          See subscription
        </button>
      </div>
    </div>
  </div>
</div>
