<h4>{{ subscription?.id }}</h4>

<input pInputText [(ngModel)]="subscription.name" placeholder="Username" />
<p-dropdown
  [options]="subscriptionTypes"
  [(ngModel)]="subscriptionType"
  optionLabel="name"
  (onChange)="onSubscriptionTypeChange($event)"
>
</p-dropdown>
<p>CreatedAt</p>
<input pInputText type="text" disabled [(ngModel)]="subscription.createdAt" />
<p>StartDate</p>
<input pInputText type="text" disabled [(ngModel)]="subscription.startDate" />
<p>ModifiedAt</p>
<input pInputText type="text" disabled [(ngModel)]="subscription.modifiedAt" />
<p>ExpiringAt</p>
<input pInputText type="text" disabled [(ngModel)]="subscription.expiringAt" />

<p-calendar
  appendTo="body"
  class="p-inputtext-sm"
  dateFormat="yy-mm-dd'T'00:00:00.0000000'Z'"
  dataType="string"
  [style]="{ width: '100%' }"
  [(ngModel)]="subscription.expiringAt"
  [touchUI]="true"
  [showButtonBar]="true"
  (onSelect)="fromDateSelected()"
></p-calendar>

<input
  pInputText
  type="text"
  disabled
  value="expira in {{ getEndDate(subscription) }} zile"
/>

<h5>SubscriptionType</h5>
id
<p>
  {{ subscription?.subscriptionType?.id }}
</p>
name
<p>
  {{ subscription?.subscriptionType?.name }}
</p>
description
<p>
  {{ subscription?.subscriptionType?.description }}
</p>
publicId
<p>
  {{ subscription?.subscriptionType?.publicId }}
</p>
userLimit
<p>
  {{ subscription?.reachedLimits?.userLimit }} / {{ subscription?.subscriptionType?.limits.userLimit }}
</p>
billLimit
<p>
  {{ subscription?.reachedLimits?.billLimit }} / {{ subscription?.subscriptionType?.limits.billLimit }}
</p>
wayBillLimit
<p>
  {{ subscription?.reachedLimits?.wayBillLimit }}/ {{ subscription?.subscriptionType?.limits.wayBillLimit }}
</p>

<button class="p-button" (click)="updateSubscription()">
  Update Subscription
</button>

<p-toast />
<p-confirmDialog />


  <p-button label="Delete Subscription" severity="danger"  (click)="confirmDeleteSubscription($event)"/>
  
