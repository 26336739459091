import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { CompaniesService } from "src/app/services/companies.service";
import { UserSelectDialogComponent } from "../shared/dialogs/user-select-dialog/user-select-dialog.component";
import { FacturiComponent } from "../facturi/facturi.component";
import { MeterGroupModule, MeterItem } from "primeng/metergroup"
import { SubscriptionService } from "src/app/services/subscriptions.service";

@Component({
  selector: "app-companie",
  standalone: true,
  imports: [ButtonModule, CommonModule, RouterModule, FacturiComponent,MeterGroupModule],
  providers: [DialogService],
  templateUrl: "./companie.component.html",
  styleUrl: "./companie.component.scss",
})
export class CompanieComponent implements OnInit {
  public company: any;
  public eFacturaAuthorized = false;
  public users: any[] = [];
  public subscriptions: any[] = [];
  ref: DynamicDialogRef | undefined;

  constructor(
    private companyService: CompaniesService,
    private ar: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private router:Router,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.ar.params.subscribe((params) => {
      this.companyService.getCompany(params["id"]).subscribe({
        next: (data) => {
          this.company = data;
          this.eFacturaAuthorized = data.efacturaAuthorized;
        },
      });
      this.companyService.getCompanyUsers(params["id"]).subscribe({
        next: (data) => {
          this.users = [];

          this.users = data;
        },
      });

      this.companyService.getSubscriptions(params["id"]).subscribe({
        next: (data) => {
          this.subscriptions = [];

          data.map((sub: any) => {
            this.subscriptionService.getLimits(sub.id).subscribe({
              next: (data) => {
                sub.limits = data;
                this.subscriptions.push(sub);
                return sub;
              }
            });
          })
        },
      });
    });
  }

  syncBills(){
    this.companyService.syncBills(this.company.id).subscribe({
      next: (data) => {
        console.log(data);
        alert("OK. Check console.");
      },
    });
  }

  addUser(){
    this.ref = this.dialogService.open(UserSelectDialogComponent, {
      focusOnShow: true,
      closeOnEscape: true,
      modal: true,
      showHeader: true,
      header: 'Adauga un utilizator',
    });
    this.ref.onClose.subscribe((user: any) => {
      console.log(user);
      this.companyService.addUser(this.company.id, user.email).subscribe({
        next: (data) => {
          console.log(data);
          alert("OK. Check console.");
        },
      });
    });
  }

  navigateToSubscription(subscription: any){
    this.router.navigate(["/subscription", subscription.id]);
  }

  getEndDate = (subscription:any): number => {
    var date = subscription.expiringAt;
    var diff = new Date(date).getTime() - new Date().getTime();
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays;
  };

  getActiveSubscriptionLabel = (sub:any): string => {
    var days = this.getEndDate(sub);
    if (days > 0) {
      return 'Activ';
    }

    return 'Expirat';
  };

  isSubscriptionActive = (sub:any): boolean => {
    var days = this.getEndDate(sub);
    return days > 0;
  };

  getMeter(x: number | undefined, y: number, label: string): MeterItem[] {
    var percent = 0;
    if (x != undefined) {
      percent = (x / y) * 100;
    }
    var label = `${label}: ${x} / ${y} `;
    return [{ value: percent, color: '#34d399', label: label }];
  }
  
}
